import Stripe from '../../1_MediaAssets/OtherBasics/Stripe.png';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import CutePets from "../../Registrations&Login/CutePets";
import { onAuthStateChanged } from 'firebase/auth';

const DonationBox = ({ onDonationSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [donationAmount, setDonationAmount] = useState('');
    const [donorEmail, setDonorEmail] = useState('');
    const [donorName, setDonorName] = useState('');
    const [donorDocumentID, setDonorDocumentID] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [customAmount, setCustomAmount] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const navigate = useNavigate();

    // eslint-disable-next-line
    const predefinedAmounts = [10, 25, 50, 100];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setAuthenticated(true);
                try {
                    const parentDocRef = doc(db, 'Parents', user.uid);
                    const parentDoc = await getDoc(parentDocRef);
                    if (parentDoc.exists()) {
                        setDonorEmail(parentDoc.data().EmailAddress);
                        setDonorName(parentDoc.data().Name);
                        setDonorDocumentID(parentDoc.id);
                        return;
                    }
                    const techDocRef = doc(db, 'Doctors', 'Technician', 'Technician', user.uid);
                    const techDoc = await getDoc(techDocRef);

                    if (techDoc.exists()) {
                        setDonorEmail(techDoc.data().EmailAddress);
                        setDonorName(techDoc.data().FirstName);
                        setDonorDocumentID(techDoc.id);
                        return;
                    }
                    const vetDocRef = doc(db, 'Doctors', 'Veterinarian', 'Veterinarian', user.uid);
                    const vetDoc = await getDoc(vetDocRef);

                    if (vetDoc.exists()) {
                        setDonorEmail(vetDoc.data().EmailAddress);
                        setDonorName(vetDoc.data().FirstName);
                        setDonorDocumentID(vetDoc.id);
                        return;
                    }

                    setError('User not found in the database.');
                } catch (fetchError) {
                    setError('Error fetching user data.');
                    console.error('Fetch error:', fetchError);
                }
            } else {
                setAuthenticated(false);
                console.log('No authenticated user found.');
            }
        });

        return () => unsubscribe();
    }, []);

    /* const handleSuccess = (transactionID) => {
        setSuccess(true);
        setError(null);

        // Llama el callback si está definido
        if (onDonationSuccess) {
            onDonationSuccess({
                transactionID,
                donationAmount,
                donorEmail,
                donorName,
            });
        }
    }; */

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setError('Stripe.js has not yet loaded.');
            setLoading(false);
            return;
        }
        // when donationAmount have value get off setError
        if (donationAmount) {
            setError(null);
        }
        if (!subscription && !donationAmount) {
            setError('Please enter a donation amount.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (paymentMethodError) {
            setError(paymentMethodError.message);
            setLoading(false);
            return;
        }

        if (subscription) {
            // Handle subscription payment intent
            
            const response = await fetch('https://rexmedicaleventsserver.vercel.app/create-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ paymentMethodId: paymentMethod.id, priceId: 'price_1999_monthly' }),
            });

            const subscriptionData = await response.json();

            if (subscriptionData.error) {
                setError(subscriptionData.error);
                setLoading(false);
                return;
            }

            setSuccess(true);
            setError(null);

            navigate('/SubscriptionSuccess', {
                state: {
                    subscriptionID: subscriptionData.subscriptionId,
                    userID: donorDocumentID,
                    handleSuccess: onDonationSuccess, 
                }
            });
        } else {
            // Handle one-time donation payment intent
            const response = await fetch('https://rexmedicaleventsserver.vercel.app/create-payment-intent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: donationAmount * 100 }),
            });

            const paymentIntentData = await response.json();
            const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(paymentIntentData.clientSecret, {
                payment_method: paymentMethod.id,
            });

            if (confirmError) {
                setError(confirmError.message);
                setLoading(false);
                return;
            }

            const donationData = {
                donorEmail,
                donorName,
                donorDocumentID,
                donationAmount,
                timestamp: serverTimestamp(),
                transactionID: paymentIntent.id,
            };

            if (!confirmError) {
                try {
                    const donationsDocRef = doc(db, 'Donations', paymentIntent.id);
                    await setDoc(donationsDocRef, donationData);
    
                    /* handleSuccess(paymentIntent.id); */
    
                    navigate('/DonationSuccess', {
                        state: {
                            transactionID: paymentIntent.id,
                            userID: donorDocumentID,
                            handleSuccess: onDonationSuccess,

                        }
                    });
                } catch (error) {
                    console.error('Error saving donation:', error);
                    setError('Error processing transaction. Please try again.');
                }
            }

        }

        setLoading(false);
    };

    const handleDonationAmountChange = (e) => {
        setDonationAmount(e.target.value);
    };

    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px', flexDirection: 'column' }}>
                <h2 style={{ fontWeight: '900', color: '#002366', fontSize: '30px', textAlign: 'center', width: '100%', marginBottom: '3px' }}>
                    {authenticated ? 'Registered Donation' : 'Anonymous Donation'}
                </h2>
                <p style={{ fontWeight: '900', color: '#686869', textAlign: 'center', width: '100%', fontSize: '13px', marginBottom: '2px' }}>
                    Your generous contribution helps us provide essential veterinary care to pets in need. 
                </p>
                <CutePets />
            </div>

            <div className='mobileresponsiveDonationBox' style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', padding: '30px', paddingTop: '0px' }}>
                
            <form className="checkout-form" style={{ width: '100%' }} onSubmit={handleSubmit}>
                <p className='nondisplaydonationinfo' style={{ color: '#686869', width: '100%', fontSize: '13px', marginTop: '0px' }}>
                    - $20: Provides a telehealth consultation for a pet in need.<br/>
                    - $25: Supports a follow-up consultation for ongoing care.<br/>
                    - $50: Funds a comprehensive care plan for a pet with chronic health issues.<br/>
                    - $100+: Expands our ability to offer specialized care and reach more pets.
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: '20px', gap: '7px', flexWrap: 'wrap' }}>
                    {['20', '25', '50', '100'].map((amount) => (
                        <div 
                            key={amount} 
                            onClick={() => { 
                                setDonationAmount(amount); 
                                setCustomAmount(false); 
                                setSubscription(false); 
                                setErrorMessage(''); 
                            }} 
                            style={{ 
                                width: '100px', 
                                padding: '10px', 
                                border: '1px solid #ddd', 
                                borderRadius: '5px', 
                                textAlign: 'center', 
                                cursor: 'pointer', 
                                backgroundColor: donationAmount === amount ? '#FFE699' : 'white', 
                                color: donationAmount === amount ? '#002366' : 'black', 
                                fontWeight: 'bold', 
                                fontSize: '13px'
                            }}>
                            ${amount}
                        </div>
                    ))}
                    <div 
                        onClick={() => { 
                            setCustomAmount(true); 
                            setSubscription(false); 
                            setErrorMessage(''); 
                        }} 
                        style={{ 
                            width: '200px', 
                            padding: '10px', 
                            border: '1px solid #ddd', 
                            borderRadius: '5px', 
                            textAlign: 'center', 
                            cursor: 'pointer', 
                            backgroundColor: customAmount ? '#FFE699' : 'white', 
                            color: customAmount ? '#002366' : 'black', 
                            fontWeight: 'bold', 
                            fontSize: '13px' 
                        }}>
                        Custom Amount
                    </div>
                </div>
                {customAmount && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%', flexDirection: 'column' }}>
                        <label>Enter Donation Amount (USD):</label>
                        <input 
                            style={{ width: '100%', borderRadius: '5px', border: '1px solid #ddd', padding: '7px 10px', fontSize: '13px' }} 
                            type="number" 
                            min="10" 
                            placeholder="Enter amount" 
                            value={donationAmount} 
                            onChange={(e) => {
                                const value = e.target.value;
                                setDonationAmount(value);
                                if (value < 10) {
                                    setErrorMessage('Donation amount must be at least $10 USD.');
                                } else {
                                    setErrorMessage('');
                                }
                            }} 
                            required 
                        />
                    </div>
                )}
                {errorMessage && <p style={{ color: 'red', fontSize: '13px' }}>{errorMessage}</p>}
                <div className="card-element-container">
                    <CardElement />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <img src={Stripe} alt='StripeLogo' style={{ width: '100px', marginTop: '10px' }} />
                </div>
                <button 
                    type="submit" 
                    style={{
                        backgroundColor: (!stripe || loading || (customAmount && donationAmount < 10)) ? '#d3d3d3' : '',
                        color: (!stripe || loading || (customAmount && donationAmount < 10)) ? '#666' : 'white',
                        cursor: (!stripe || loading || (customAmount && donationAmount < 10)) ? 'not-allowed' : 'pointer',
                    }}
                    disabled={!stripe || loading || (customAmount && donationAmount < 10)}>
                    {loading ? 'Processing...' : subscription ? 'Subscribe for $19.99/month' : `Donate $${donationAmount}`}
                </button>
                {error && <div className="error-message">{error}</div>}
                {success && <div className="success-message">Payment Successful!</div>}
            </form>
            
            </div>
        </>
    );
};

export default DonationBox;