import { NavLink } from "react-router-dom";
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
// import Cover from '../../1_MediaAssets/MainPages/PetParentCover.jpg';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
// import { GoPlus } from "react-icons/go";
// import Texture from '../../1_MediaAssets/OtherBasics/Texture.png';
import img1 from '../../1_MediaAssets/Homepage/test1.png';
import img2 from '../../1_MediaAssets/Homepage/test2.png';
import img3 from '../../1_MediaAssets/Homepage/test3.png';
import '@fortawesome/fontawesome-free/css/all.min.css';
import BackgroundMembership from '../../1_MediaAssets/Homepage/membershipsbg1.png'
import profileDoc from '../../1_MediaAssets/Homepage/profileDoc.png'
import { Accordion } from 'react-bootstrap';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import TestimonialCarousel from './TestimonialCarrousel.jsx';
import dogImage from '../../1_MediaAssets/MainPages/dogimage.png'
const PetParents = () => { 

    const location = useLocation();
    const state = location.state;

    console.log("Received state:", state);
    
    const cards = [
      {
        title: "Friend of Rex Vets",
        description: "Join as a Friend of Rex Vets and help us provide Affordable telehealth consultations to pets in need. Your monthly contribution ensures ongoing care for pets who otherwise might not receive it.",
        contribution: "Choose from $10, $25, $50, or customize your amount.",
        benefits: [
          "Regular updates on our impact",
          "Recognition as a supporter",
          "Access to exclusive online events"
        ],
        button: "Become a Friend"
      },
      {
        title: "Community Champion",
        description: "Become a Community Champion by making a larger impact. Your support helps fund critical care for pets in underserved communities, ensuring that every pet has access to the help they need.",
        contribution: "$100, $250, $500, or customize your amount.",
        benefits: [
          "All Friend-level benefits",
          "Personalized impact stories",
          "Special recognition on our website"
        ],
        isRecommended: true,
        button: "Join as a Champion"// Esta es la tarjeta destacada
      },
      {
        title: "Pet Care Hero",
        description: "As a Pet Care Hero, your generous support enables us to provide comprehensive care services and expand our reach to more pets in need. Help us change lives, one pet at a time.",
        contribution: "$1,000 or more.",
        benefits: [
          "All previous benefits",
          "Exclusive invitations to virtual events with our veterinarians",
          "Feature stories in our newsletters"
        ],
        button: "Be a Hero"
      },
      // {
      //   title: "Make a One-Time Gift",
      //   description: "Every contribution, no matter the size, makes a difference. Make a one-time donation to help us continue providing free telehealth services to pets in need.",
      //   contribution: "$10, $25, $50, $100, or customize your amount.",
      //   benefits: []
      // }
    ];
    // function navPayAnnuallyCheckout() {
    //     navigate('/PayAnnuallyCheckout', { state });
    // }

    // function navPayPerAppointmentCheckout() {
    //     navigate('/PayPerAppointmentCheckout', { state }); 
    // }
    return ( 
        <> 
            <Navbar />
            {/* height: 'calc(100vh - 50px)' backgroundImage:`url('${PawTexture}')`, */}

            <div className="veterinary-container">
      <div className="veterinary-background">
        <img src={BackgroundMembership} alt="Background" className="veterinary-background-image" />
      </div>
      <div className="veterinary-content">
      <TextAnim Body={<h1 className="veterinary-title">Help Us Provide Affordable Pet Telehealth. </h1>} />
      <TextAnim Body={ <p className="veterinary-description">
      At Rex Vets, we believe every pet deserves access to quality veterinary care, regardless of their owner's financial situation. As a non-profit organization, we offer affordable telehealth consultations to pets in need. To sustain this mission, we rely on the generosity of supporters like you. By joining our support community, you help us continue providing essential care to pets everywhere.”
        </p>} />
        <button className="veterinary-subscribe-button" style={{color:'white'}}><NavLink to="/FindAVet" style={{color:'white'}}>Donate now </NavLink></button>
      </div>
      <div className="veterinary-chat-container">
        <div className="veterinary-chat-message veterinary-chat-message-left">
          <img src={profileDoc} alt="Vet" className="veterinary-chat-avatar" />
          <div className="veterinary-chat-bubble">Hi Alex, how is Bella doing today?</div>
        </div>
        <div className="veterinary-chat-message veterinary-chat-message-right">
          <div className="veterinary-chat-bubble">She seems a bit lethargic and isn't eating much.</div>
        </div>
        <div className="veterinary-chat-message veterinary-chat-message-right">
          <div className="veterinary-chat-bubble">Just some occasional coughing.</div>
        </div>
        <div className="veterinary-chat-message veterinary-chat-message-left">
          <img src={profileDoc} alt="Vet" className="veterinary-chat-avatar" />
          <div className="veterinary-chat-bubble">Let's do a virtual check-up to see how we can help.</div>
        </div>
      </div>
    </div>
    <div class="newDonationsection">
    <TextAnim Body={
                            <h3 className='fontsizee text-center' style={{ color: '#001337', fontWeight: '900', width: '100%', paddingBottom: '30px', paddingTop:'30px'}}><span style={{ color: '#2b9159' }}>Affordable</span> Pet Care You Can Rely On </h3>
                        } />
    <div className="cardsBenefitsdonate">
      {cards.map((card, index) => (
        <div className={`card card-benefit ${card.isRecommended ? "recommended" : ""}`} key={index}>
          <h3 class="titlecard-benefit">{card.title}</h3>
          <p>{card.description}</p>
          <p><strong>Suggested Contribution:</strong> {card.contribution}</p>
          {card.benefits.length > 0 && (
            <div className="benefits">
              <p><strong>Benefits:</strong></p>
              <ul>
                {card.benefits.map((benefit, benefitIndex) => (
                  <li key={benefitIndex}>
                    <FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} /> {benefit}
                  </li>
                ))}
              </ul>
            </div>
          )}
           <NavLink to="/DonatePage1" style={{ color: 'white' }}><button>{card.button}</button></NavLink>
        </div>
      ))}
    </div>
    </div>
    <div style={{backgroundColor:'#002261', padding:'40px'}}>
    <div className="support-info-wrapper">
      <div className="support-info-image">
        <img src={dogImage} alt="Support Pets" />
      </div>
      
      <div className="support-info-container">
      <TextAnim Body={
                            <h3 className='fontsizee' style={{ color: 'white', fontWeight: '900', width: '100%', paddingBottom:'10px'}}><span style={{ color: '#2b9159' }}>Your Support </span> Makes a Difference </h3>
                        } />
        <p>
          Every dollar you contribute goes directly toward providing affordable telehealth services to pets who need them most. Here’s how your support helps:
        </p>
        <ul>
          <li><strong>$10:</strong> Provides a telehealth consultation for a pet in need.</li>
          <li><strong>$25:</strong> Supports a follow-up consultation for ongoing care.</li>
          <li><strong>$50:</strong> Funds a comprehensive care plan for a pet with chronic health issues.</li>
          <li><strong>$100+:</strong> Expands our ability to offer specialized care and reach more pets.</li>
        </ul>
      </div>
    </div>
    </div>
    <div class="newDonationsection">
    <TestimonialCarousel />
    </div>
            {/* <div className='DogCatImgTextDiv' style={{ position: 'relative', backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '80vh' }}>
                <div className='PackageHolderText'>
                    <div className='PackageType' style={{ background: 'transparent' }}>
                        <TextAnim Body={
                            <h3 className='fontsizee' style={{ color: 'white', fontWeight: '900', width: '100%' }}><span style={{ color: '#2b9159' }}>Affordable</span> Pet Care You Can Rely On </h3>
                        } />
                        <TextAnim Body={
                            <p className='fontsizee' style={{ color: 'white', marginTop: '10px', width: '100%' }}>Choose between paying per appointment or opting for Rex Annual Plan for unlimited annual access.</p>
                        } />
                        <TextAnim Body={
                            <table className="custom-table" style={{ width: '100%', fontSize: '13px', marginTop: '10px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />24/7 access to licensed vets</td>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />Customized treatment plans</td>
                                    </tr> 
                                    <tr>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />Ongoing care & follow-up</td>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />High quality video calls & messaging</td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />Prescription medications</td>
                                        <td style={{textAlign: 'left'}}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />Vet care when you need it</td>
                                    </tr>
                                </tbody>
                            </table>
                        } />
                    </div>
                </div>
                <div className='PackageHolder' style={{marginTop: '50px', marginBottom: '50px'}}>
            <div className='PackageType' style={{ border: '4px solid #efb810', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', position: 'relative' }}>
                <div className='recommended-label'>RECOMMENDED</div>
                <h4 style={{ fontWeight: '900', marginTop: '20px' }}>Rex Annual Plan</h4>
                <p style={{ fontSize: '16px', marginTop: '0px', width: '65%', lineHeight: '15px' }}>Gain round-the-clock access to our services with a full-year subscription to Rex Plus.</p>
                <p style={{ fontSize: '16px', margin: '10px 10px 10px 0px', width: '100%', color: 'black', borderTop: '0.5px solid #8CC084' }}></p>
                <p style={{ fontSize: '16px', lineHeight: '40px' }}>$11.25 USD/month<br />Billed annually at $135 USD/year</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10px' }}>
                    <button style={{ borderRadius: '10px', width: '100%', color: 'black', background: '#efb810', fontWeight: '900', fontSize: '20px' }}>
                        <NavLink to="/FindAVet" style={{ color: 'black' }}>Subscribe</NavLink>
                    </button>
                </div>
                <p style={{ fontSize: '16px', marginTop: '10px' }}>Includes:</p>
                <p style={{ fontSize: '16px', marginTop: '4px' }}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />4 video appointments/year</p>
                <p style={{ fontSize: '16px', marginTop: '2px' }}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />Covers multiple pets</p>
            </div>
            <div className='PackageType'>
                <h4 style={{ fontWeight: '900' }}>Rex Per Appointment</h4>
                <p style={{ fontSize: '13px', marginTop: '0px', width: '65%', lineHeight: '15px' }}>For pet parents looking to begin with a single appointment.</p>
                <p style={{ fontSize: '13px', margin: '10px 10px 10px 0px', width: '100%', color: 'black', borderTop: '0.5px solid #8CC084' }}></p>
                <p style={{ fontSize: '13px', width: '65%', lineHeight: '15px' }}>Starting at $40 USD/one-time<br />Single appointment billed at marketplace rates.</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10px' }}>
                    <button style={{ borderRadius: '10px', width: '100%', color: 'black', background: '#d7fad2' }}>
                        <NavLink to="/FindAVet">One-time checkout</NavLink>
                    </button>
                </div>
                <p style={{ fontSize: '13px', marginTop: '10px' }}>Includes:</p>
                <p style={{ fontSize: '13px', marginTop: '4px' }}><FaCheck style={{ color: '#8CC084', margin: '0px 5px 0px 0px' }} />1 online vet appointment</p>
            </div>
        </div>
            </div>  */}
           
            <div className="Accordions-andText"><h2 className="text-center" style={{paddingTop:'60px', paddingBottom: '60px', color:"white"}}>Frequently Asked Questions</h2>
            <div className="accordionClassParents">
            <p className="LeftTexto" style={{color: "white"}}>Choose Rex Vets and give your pets the expert care they deserve, anytime, anywhere. Join us and experience stress-free pet parenting today!</p>
            <Accordion className="accordionAchicar">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Rex Vets?</Accordion.Header>
        <Accordion.Body>
        Rex Vets is committed to providing affordable online veterinary services to ensure that pet health reaches remote areas and underserved communities. We operate solely through donations, allowing us to make a positive impact on pets and their owners who may otherwise have limited access to veterinary care.
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="2">
        <Accordion.Header>What types of medical conditions can Rex Vets assist with through telemedicine?</Accordion.Header>
        <Accordion.Body>
        Rex Vets can provide advice on a wide range of non-emergency medical conditions, including skin problems, digestive issues, behavioral concerns, and general wellness. You can access this information on our <a href='./WhatWeTreat' style={{color:'white', fontWeight:'700'}}>What We Treat</a> page. However, in cases of emergencies or when physical examinations are required, we recommend seeking in-person veterinary care.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item style= {{borderRadius:'8px'}} eventKey="3">
        <Accordion.Header>How secure is my pet’s medical information when using Rex Vets?</Accordion.Header>
        <Accordion.Body>
        We take the privacy and security of your pet's medical information seriously. All consultations and records are encrypted and stored following strict confidentiality standards to ensure that your data is fully protected.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    </div>
            {/* <div className="AllofCards">
            <Container maxWidth="lg" className="rex-vets-container">
            <h2 class="tittleHealthSolution"> Rex Vets: Your Comprehensive Pet Health Solution</h2>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={4}>
          <Card className="rex-vets-card">
            <div className="rex-vets-card-title" style={{ backgroundImage:  `url(${img2})` }}>

            </div>
            <CardContent>
            <Typography  variant="h6"className="rex-vets-subtitle"  sx={{ mb: 1, fontWeight: 'bold',  fontSize: '30px', color: '#002366'}}  >
                Always-On Veterinary Support
              </Typography>
              <Typography variant="body1" className="rex-vets-text">

                <i className="fas fa-check"></i> Professional care at your convenience.<br/>
                <i className="fas fa-check"></i> Choose the method of connection that suits you.<br/>
                <i className="fas fa-check"></i> Access Rex Vets Online Pharmacy.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className="rex-vets-card">
            <div className="rex-vets-card-title" style={{ backgroundImage: `url(${img3})` }}>

            </div>
            <CardContent>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold', color: '#002366',  fontSize: '30px'}} className="rex-vets-subtitle">
                Live Chat
              </Typography>
              <Typography variant="body1" className="rex-vets-text">
                <i className="fas fa-check"></i> Get immediate answers through live chat.<br/>
                <i className="fas fa-check"></i> Assess the urgency of an issue.<br/>
                <i className="fas fa-check"></i> Review nutrition and behavior plans on the go.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className="rex-vets-card">
            <div className="rex-vets-card-title" style={{ backgroundImage:  `url(${img1})` }}>

            </div>
            <CardContent>
            <Typography variant="h6" className="rex-vets-subtitle"  sx={{ mb: 1, fontWeight: 'bold', color: '#002366' ,  fontSize: '30px'}}>
                Online Appointments
              </Typography>
              <Typography variant="body1" className="rex-vets-text">

                <i className="fas fa-check"></i> Book appointments with top-rated vets at your convenience.<br/>
                <i className="fas fa-check"></i> Choose from thousands of licensed veterinarians.<br/>
                <i className="fas fa-check"></i> Access both prescription and over-the-counter medications seamlessly.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
    </div> */}


            {/* <div className="HeroMainDiv" style={{backgroundImage:`url('${Cover}')`, backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#002366', marginTop: '70px', minHeight: '450px'}}>
                <div className="HeroMainDivImage2">
                </div>
    
                <div className="HeroMainDivText_Pets">
                    <div className='HeroMainDivText2' style={{padding: '30px', borderRadius: '15px'}}>
                        <TextAnim Body={
                            <h4 style={{fontWeight: '900', marginBottom: '10px', fontSize: '40px'}}>Easily access virtual veterinary care from anywhere with Rexvets</h4>
                        }/>
                        <TextAnim Body={
                            <p style={{marginBottom: '30px', marginTop: '10px'}}>Gain access to round-the-clock licensed vet care, limitless instant live chat, customized treatment plans, and prescriptions wherever possible. Enjoy these benefits for all your pets with just one subscription through Rexvets.</p>
                        }/>
                        <div className="LogRegButton">
                            <TextAnim Body={
                                <NavLink  to="/Login">
                                    <button style={{width: '200px', borderRadius: '200px'}}>Sign in</button>
                                </NavLink>
                            }/>

                            <TextAnim Body={
                                <NavLink  to="/RegistrationPetParents">
                                    <button style={{width: '200px', borderRadius: '200px'}}>Register</button>
                                </NavLink>
                            }/>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div style={{height: '200px', width: '100vw', backgroundColor: '#002366',  display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px'}}>
                <h2 style={{color: 'white', width: '80vw', fontSize: '40px'}}>Rexvets is an exclusive platform that allows you to pick the veterinarian attending to your pet's needs.</h2>
            </div> */}

            <Footer />
            
        </>
        )
}

export default PetParents