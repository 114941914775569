import mobile from '../../1_MediaAssets/Homepage/mobilerex.png';
import React from "react";
import PrincipalImage from "../../1_MediaAssets/MainPages/1920x327.png";
import PrincipalImgWorks from "../../1_MediaAssets/MainPages/PrincipalImgWorks.png";
import SecondImg from "../../1_MediaAssets/MainPages/SecondImg.png";
import Howitswork1 from "../../1_MediaAssets/MainPages/HowitsWork1.svg";
import Howitswork2 from "../../1_MediaAssets/MainPages/HowitsWork2.svg"; 
import Howitswork3 from "../../1_MediaAssets/MainPages/HowitsWork3.png";
import Howitswork4 from "../../1_MediaAssets/MainPages/HowitsWork4.png";
import Howitswork5 from "../../1_MediaAssets/MainPages/HowitsWork5.png";
import Footer from "../../Navbars/Footer/index.js";
import Navbar from "../../Navbars/NavbarMain/NavbarMain.js";
import PawTexture from '../../1_MediaAssets/Homepage/PawTexture.png';
import Texture from '../../1_MediaAssets/OtherBasics/Texture.png'
import'../../1_MediaAssets/Styles/App.css';
import { NavLink } from 'react-router-dom';
const Works = () => {
  const brands = [
    { id: 1, name: 'MES' },
    { id: 2, name: 'HUFFPOST' },
    { id: 3, name: 'TechCrunch' },
    { id: 4, name: 'yahoo!news' },
    { id: 5, name: 'THE GLOBE AND MAIL' }
  ];
  const doubledBrands = [...brands, ...brands];
  return (
    <>
      <Navbar />
      <main className="main-content">
  
        <section className="bg-color-f6"   style={{ backgroundImage: `url('${PawTexture}')` }}>
        <div className="main-banner-works" style={{backgroundImage: "url("+PrincipalImage+")"}}>
                <div className="container inner-content">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6 col-sm-7">
                            <h6 className="title-ext text-primary">How Our Telehealth Services Work</h6>
                            <h1>Expert care at your fingertips, offering professional support for all your pet's needs. From check-ups to emergencies, we are just a click away.</h1>
                            <NavLink to="/FindAVet" className="btn-theme">Talk to a vet</NavLink>
                        </div>
                    </div>
                </div>

            </div>
          <div className="title-main">
          <h2 className="title">How it Works</h2>
          </div>

          <div className="card-container-works">

            <div className="card-works">
              <img src={Howitswork2} alt="How it works 1" />
              <h3>Schedule an Affordable <br/> Consultation</h3>
            </div>

            <div className="card-works">
              <img src={Howitswork3} alt="How it works 2" />
              <h3>Speak with a <br/> Veterinarian</h3>
            </div>

            <div className="card-works">
              <img src={Howitswork1} alt="How it works 3" />
              <h3>Order Medications <br/> Online</h3>
            </div>

            <div className="card-works">
              <img src={Howitswork4} alt="How it works 3" />
              <h3>Receive Medications <br/> at Home</h3>
            </div>

          </div>
          <div className="card-container-works">
          <NavLink to="/FindAVet" style={{marginTop: '30px'}}>
              <button style={{fontSize: '20px', padding: '20px 25px', borderRadius: '50px'}}>Get Started Today</button>
            </NavLink>
            </div>
          <div className="Pasos-wrapper" style={{background: '#002366'}}>
            <div className="Pasos-container" style={{background: 'transparent'}}>
              <div className="Pasos-content">
                <h1 className="Pasos-title" style={{color: 'white'}}>Connecting with a Rex Vets veterinarian is easy and stress-free. </h1>
                <p className="Pasos-subtitle" style={{color: '#a3a3a3', marginTop: '20px'}}>Here's how it works:</p>
                <div className="Pasos-steps">
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">1</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title" style={{color: 'white'}}>Schedule an Affordable Consultation:</h2>
                      <p className="Pasos-step-description" style={{color: '#a3a3a3'}}>Find top-rated vets near you and pick the best one for your needs.</p>
                    </div>
                  </div>
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">2</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title" style={{color: 'white'}}>Connect Online:</h2>
                      <p className="Pasos-step-description" style={{color: '#a3a3a3'}}>Choose a time that works for you and your pet for a consultation.</p>
                    </div>
                  </div>
                  <div className="Pasos-step">
                    <span className="Pasos-step-number">3</span>
                    <div className="Pasos-step-text">
                      <h2 className="Pasos-step-title" style={{color: 'white'}}>Order Medications Online</h2>
                      <p className="Pasos-step-description" style={{color: '#a3a3a3'}}>Get specific guidance for your pet's health, including advice, triage, and second opinions.</p>
                      <p className="Pasos-step-description" style={{color: '#a3a3a3', fontWeight: '900'}}>Note: Prescriptions are not free and must be purchased separately.</p>
                    </div>
                  </div>


                  <NavLink to="/FindAVet" className="Pasos-talk-button" style={{fontSize: '18px'}}>Get Started Today</NavLink>
                </div>
              </div>
              <div className="Pasos-image-container" style={{background: 'transparent'}}>
                <img src={mobile} alt="Vet consultation" className="Pasos-image" style={{maxWidth: '390px'}}/>
              </div>
            </div>
          </div>


          <div className="container">
            <div className="about-item about-item-style2 position-relative">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-thumb text-center">
                    <img classname="principal-images"
                      src={PrincipalImgWorks}
                      alt="Image-HasTech"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <div className="section-title shape-left">
                      <h2 className="title">Our Mission</h2>
                    </div>
                    <p>
                      At Rex Vets we recognize that access to veterinary care
                      can sometimes be difficult for a number of reasons. Our
                      goal is to close the gap: to offer cutting-edge
                      telemedicine for pets, based on scientific principles,
                      with consistent monthly treatments and unwavering support
                      from top-notch veterinarians.
                    </p>
                    <p className="text-color-theme">
                      Because every pet deserves the opportunity to lead a
                      joyful and healthy life.
                    </p>
                    <NavLink to="/VetsandTechs" className="btn-theme">
                      Join Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-color-f6 separate"  style={{position: 'relative', backgroundImage: `url('${Texture}')`, backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <div className="container">
            <div className="about-item about-item-style2 position-relative">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-content">
                    <div className="section-title shape-left">
                      <h2 className="title">Veterinary Medicine Reimagined</h2>
                    </div>
                    <p>
                      Rex Vets has reimagined veterinary care, putting it right
                      at your fingertips. Instead of making pets visit clinics,
                      we prioritize stress reduction and patient happiness,
                      making the effort well worth it.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-thumb text-center">
                    <img
                      src={SecondImg}
                      alt="Image-HasTech"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="marquee-container">
      <div className="static-text">Award winning & featured in...</div>
      <div className="marquee">
        <div className="track">
          {doubledBrands.map((brand, index) => (
            <div key={index} className="brand">{brand.name}</div>
          ))}
        </div>
      </div>
    </div> */}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Works;