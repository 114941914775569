import React, { useEffect } from 'react';
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import donateimg1 from '../../1_MediaAssets/Homepage/Donateimg1.jpg';
import donateimg2 from '../../1_MediaAssets/Homepage/Donateimg2.jpg';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import Footer from '../../Navbars/Footer/index.js';
import Texture from '../../1_MediaAssets/OtherBasics/Texture.png';
import { NavLink } from 'react-router-dom';
import Video from './bgVideoDonate.mp4';

const Donate = () => {
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.donate-section-container');
            sections.forEach(section => {
                const sectionTop = section.getBoundingClientRect().top;
                if (sectionTop < window.innerHeight - 100) {
                    section.classList.add('scrolled');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Navbar />
            <div className="optionsbg">
 
                <div className='videoDivDonate'>
                    {/* <video autoPlay muted loop className="background-video">
                        <source src={Video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video> */}
                    <h1>Our Mission</h1>
                    <p style={{margin: '10px 10px 0px 10px'}}>At Rex Vets, we understand that access to quality veterinary care can be challenging for many pet owners due to various factors. As a non-profit organization, our mission is to bridge this gap by providing affordable, cutting-edge telemedicine services for pets. We are committed to offering scientifically-based care with consistent monthly treatments and unwavering support from highly skilled veterinarians.</p>
                    <p style={{margin: '10px 10px 0px 10px'}} className='hideme'>Our dedication goes beyond just treating pets; we aim to empower pet owners with the knowledge and resources they need to ensure their furry friends lead joyful and healthy lives. By eliminating financial barriers and offering our services at no cost, we strive to make top-tier veterinary care accessible to all. We believe that every pet deserves the chance to thrive, and we are here to make that possible.</p>
                    <p style={{margin: '10px 10px 0px 10px'}} className='hideme'>At Rex Vets, our focus is on creating a community where pet health and well-being are prioritized. We work tirelessly to advance veterinary practices, support animal welfare initiatives, and provide compassionate care to those who need it most. Together, we can make a significant difference in the lives of pets and their families, ensuring that no pet is left behind due to lack of access to essential care.</p>
                </div>


                <div className="help-donate-component">
                    <div className="help-donate-content">
                        <TextAnim className="reducemobFont" Body={<h1 className="help-donate-title">Donate to the cause</h1>} />
                        <p className="help-donate-text hideme">Your generous contribution helps us provide essential veterinary care to pets in need. Every donation ensures that more animals receive the treatment and love they deserve.</p>
                        <p className="help-donate-text hideme">Together, we can make a difference in their lives.</p>
                        <NavLink to="/DonatePage1" className="btn-theme btnDonate">Donate</NavLink>
                    </div>
                </div>

                
            
                <div className="donate-section-container">
                    <div className="donate-image-wrapper">
                        <div className="donate-image-container" style={{ backgroundImage: `url('${donateimg1}')` }}></div>
                    </div>
                    <div className="donate-text-container" style={{ backgroundImage: `url('${Texture}')` }}>
                        <h1>Contributions at Work</h1>
                        <p>Your transactions with Rex Vets contribute to funding impactful outreach programs. These include providing support to shelters, facilitating crucial medical treatments for animals in distress, and contributing to community outreach initiatives. Your choice to use Rex Vets goes beyond consultations, making a meaningful impact in the lives of animals in need.</p>
                    </div>
                </div>

                <div className="donate-section-container">
                    <div className="donate-text-container" style={{ backgroundImage: `url('${Texture}')`, textAlign: 'right' }}>
                        <h1>Paws for a Cause: Additional Contributions</h1>
                        <p>Join us in extending a helping paw to animals in need! You now have the opportunity to contribute even more by making an additional donation to our Animal Welfare Fund. Your extra contribution will go directly to supporting shelters, providing crucial medical treatments for distressed animals, and contributing to community outreach initiatives. Every small gesture adds up to make a big difference in the lives of those who need it the most. Click below to give a little extra love and care to our furry friends. Together, let's make a lasting impact on the well-being of animals in need.</p>
                    </div>
                    <div className="donate-image-wrapper">
                        <div className="donate-image-container" style={{ backgroundImage: `url('${donateimg2}')` }}></div>
                    </div>
                </div>
            </div>
            
            <div className="connect-options">
                <h3 className="connect-title">Connect with us</h3>
                <div className="connect-buttons">
                    <a href="https://www.facebook.com/rexvets" className="connect-button" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i> Facebook
                    </a>
                    <a href="https://www.instagram.com/rexvets/" className="connect-button" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i> Instagram
                    </a>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Donate;
