import React from 'react';
import Footer from '../../Navbars/Footer/index.js';
import Navbar from '../../Navbars/NavbarMain/NavbarMain.js';
import imgShop1 from '../../1_MediaAssets/Homepage/imgShop.png';
import imgShop2 from '../../1_MediaAssets/Homepage/imgShop2.png';
import { NavLink } from 'react-router-dom';
import TextAnim from '../../1_MediaAssets/Styles/FadeAnimation.jsx';
import bg1 from '../../1_MediaAssets/VetsandTechs/image1.png';
import image1 from '../../1_MediaAssets/Homepage/image1.png'
import image2 from '../../1_MediaAssets/MainPages/image2.png'
const OurMission = () => {
  return (
    <> 
            <Navbar />
            <div className="GetAPrescription-container">
      <div className="Our-background">
        <div className="GetAPrescription-content">
          <h1 className="GetAPrescription-title">Our Mission: Affordable Veterinary Care for All</h1>
          <p className="OurMission-description">At Rex Vets, our mission is simple: to ensure that every pet, regardless of their owner's financial situation or location, has access to compassionate, high-quality veterinary care. As a non-profit organization, we are proud to offer affordable telehealth consultations to pet owners everywhere, helping pets stay healthy and happy from the comfort of their homes.</p>
          <div className='btn-marginPr'><NavLink to="/DonatePage2" className="GetAPrescription-button"> Donate Now</NavLink></div>
        </div>
      </div>
      <div className="RexVetsShop-container" style={{backgroundColor:'rgb(0, 34, 97)'}}>
        <div className="RexVetsShop-content">
          <div className="RexVetsShop-text">
            <h2 className="RexVetsShop-title">Why We Exist</h2>
            <p className="RexVetsShop-description" style={{fontSize:'18px'}}>We understand that veterinary care can be costly and challenging to access, especially for those in remote areas or facing financial hardship. That's why we’ve made it our mission to bridge the gap with affordable, accessible telehealth services. Our team of dedicated veterinarians and volunteers works tirelessly to provide expert advice, treatment plans, and ongoing support—all at no cost to the pet owners we serve.</p>
            <NavLink to="/PetParents"><button className="RexVetsShop-button">Learn More</button></NavLink>
          </div>
          <div className="RexVetsShop-image-container">
            <img src={image1} alt="Pet Products" className="Our-image" />
          </div>
        </div>
      </div>
      <div  style={{backgroundColor:'#FFFFF0'}}>
      <div className="GetAPrescription-features">
        <h1 className="titleWeAim"style={{color:'rgb(0, 34, 97)', fontFamily:'MyFont', fontWeight:'600', margin:'auto 0', marginRight:'30px'}}>We aim to:</h1>
        
        <div className="GetAPrescription-feature">
          <div className="Our-feature-icon">
          <i class="fas fa-globe"></i>
          </div>
          <h4 style={{color:'rgb(0, 34, 97)', fontWeight:'700'}}>Expand Access</h4>
          <p style={{fontSize:'16px', fontWeight:'400', marginTop:'10px', color:'rgb(0, 34, 97)'}}>Provide affordable telehealth services to pets and families who might otherwise go without care.</p>
        </div>
        <div className="GetAPrescription-feature">
          <div className="Our-feature-icon">
          <i class="fas fa-hands-helping"></i>
          </div>
          <h4 style={{color:'rgb(0, 34, 97)', fontWeight:'700'}}>Support Communities</h4>
          <p style={{fontSize:'16px', fontWeight:'400', marginTop:'10px', color:'rgb(0, 34, 97)'}}>Focus on underserved communities, offering education and resources to promote better pet health.
</p>
        </div>
        <div className="GetAPrescription-feature">
          <div className="Our-feature-icon">
          <i class="fas fa-heart"></i>
          </div>
          <h4 style={{color:'white', fontWeight:'700', color:'rgb(0, 34, 97)'}}>Promote Compassion</h4>
          <p style={{fontSize:'16px', fontWeight:'400', marginTop:'10px', color:'rgb(0, 34, 97)'}}>Ensure that every pet owner feels supported, understood, and empowered to care for their beloved animals.</p>
        </div>

      </div>
      </div>
    </div>
    <div   style={{backgroundColor:'rgb(0, 34, 97)'}}>
    <div className="Info-info-wrapper">
      <div className="support-info-image">
        <img src={bg1} alt="Support Pets" style={{width:'700px'}}/>
      </div>
      
      <div className="support-info-container" >
      <TextAnim Body={
                            <h3 className='fontsizee' style={{ color: 'white', fontWeight: '900', width: '100%', paddingBottom:'10px'}}><span style={{ color: '#2b9159' }}>How We  </span> Achieve Our Mission </h3>
                        } />
        <p style={{fontSize:'18px'}}>
        Through our innovative telehealth platform, Rex Vets connects pet owners with experienced veterinarians who provide virtual consultations, treatment recommendations, and follow-up care. By eliminating the barriers of cost and travel, we bring essential veterinary services directly into homes across the country. Our work is made possible thanks to the generous support of donors, sponsors, and volunteers who share our vision of a world where no pet suffers due to lack of access to care.

        </p>
      
      </div>
    </div></div>
      <div className="RexVetsOur-info-container">
      <div className="RexVetsOur-info-content">

        <div className="RexVetsOur-info-text"  style={{color:'rgb(0, 34, 97)'}}>
          <h2 className="RexVetsOur-info-title">The Impact of Your Support</h2>
          <p style={{fontSize:'19px', fontWeight:'600', marginBottom:'20px'}}>As a non-profit organization, we rely on the generosity of our community to continue providing affordable telehealth services. Every donation helps us:
</p>
          <ul className="RexVetsOur-info-list" >
            <li className="RexVetsOur-info-item">
              <div className="RexVetsOur-info-icon">1</div>
              <p className="RexVetsOur-info-description"  style={{color:'rgb(0, 34, 97)'}}>Keep our telehealth services affordable for all.</p>
            </li>
            <li className="RexVetsOur-info-item">
              <div className="RexVetsOur-info-icon">2</div>
              <p className="RexVetsOur-info-description"  style={{color:'rgb(0, 34, 97)'}}>Fund essential medical supplies and technology needed for virtual consultations.
</p>
            </li>
            <li className="RexVetsOur-info-item">
              <div className="RexVetsOur-info-icon">3</div>
              <p className="RexVetsOur-info-description"  style={{color:'rgb(0, 34, 97)'}}>Support outreach programs that educate pet owners and promote animal welfare.
</p>
            </li>
          </ul>
          <p style={{fontSize:'19px', fontWeight:'700'}}>"Together, we can make a difference, one virtual visit at a time.”</p>
        </div>
        <div className="RexVetsOur-info-image-container">
          <img src={image2} alt="Pet Products" className="RexVetsOur-info-image" />
        </div>
      </div>
    </div>
<Footer />


</>
  );
};

export default OurMission;