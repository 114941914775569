import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';


const VideoCallVetsandParents = () => {
    const { roomId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const zegoContainerRef = useRef(null); // Ref for the Zego container

    function randomID(len) {
        let result = '';
        const chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
        const maxPos = chars.length;
        for (let i = 0; i < len; i++) {
            result += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return result;
    }

    // Función para obtener el token desde la API
    async function getTokenFromServer() {
        try {
            const response = await fetch('/api/generate-token', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ roomId, userId: randomID(5) }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error al obtener el token:', errorText);
                return null;
            }

            const data = await response.json();
            return data.token;
        } catch (error) {
            console.error('Error llamando a la API:', error);
            return null;
        }
    }

    // Inicializar Zego
    async function initZego() {
        try {
            const token = await getTokenFromServer();
            if (!token) {
                console.error('No se pudo obtener el token. Verifica tu API.');
                return;
            }

            const zp = ZegoUIKitPrebuilt.create(token);

            zp.joinRoom({
                container: zegoContainerRef.current,
                sharedLinks: [
                    {
                        name: 'Personal link',
                        url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomId}`,
                    },
                ],
                scenario: {
                    mode: ZegoUIKitPrebuilt.VideoConference,
                },
                showMicrophoneControl: true,
                showSoundWave: true,
                showSpeakerControl: true,
                enableVoiceChange: false,
            });

            // Opcionalmente iniciar la grabación
            await startRecording(roomId);
        } catch (error) {
            console.error('Error inicializando Zego:', error);
        }
    }

    // Iniciar grabación
    async function startRecording(roomId) {
        console.log('Enviando roomId al servidor:', roomId); // Log roomId before sending
        try {
            const response = await fetch('https://rexvetsvideocallserver.vercel.app/start-recording', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ roomId }),
            });

            if (response.ok) {
                setIsRecording(true);
            } else {
                const errorText = await response.text();
                console.error('Fallo al iniciar la grabación:', errorText);
            }
        } catch (error) {
            console.error('Error iniciando la grabación:', error);
        }
    }

    // Hook para ejecutar initZego al montar el componente
    let isInitialized = false; // Variable para evitar reinicializaciones
    useEffect(() => {
        if (!isInitialized && zegoContainerRef.current && roomId) {
            initZego();
            isInitialized = true;
        }
    }, [roomId]);
    
    return (
        <div className="dashboardvideocall">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: '#fff',
                    borderRadius: '10px',
                    height: '100%',
                    minHeight: '100vh',
                    width: '100%',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
            >
                <div
                    ref={zegoContainerRef}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100vw',
                        minHeight: '100vh',
                    }}
                ></div>
                {isRecording && (
                    <p
                        style={{
                            background: 'red',
                            color: 'white',
                            fontWeight: '900',
                            width: '100vw',
                            padding: '10px 20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Recording in progress...
                    </p>
                )}
            </div>
        </div>
    );
};

export default VideoCallVetsandParents;
